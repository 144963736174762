@charset 'utf-8';

@import
'base/girds',     // xs:0, sm:576px (small), md:768px (medium), lg:992px (large), xl:1200px (big) (@include respond-to(big){})
'base/colors',    // color(<key>) | tint(<key>, <percent>) [rozjaśnienie] | shade(<key>, <percent>) [przyciemnienie]
'base/gradients', // gradient(<key>)
'base/shadows';   // shadow(<key>)

@import
'modules/finder',
'fonts/fonts';

//main {
//	.um-36.um {
//		max-width: 450px;
//	}
//	.um {
//		margin-left: auto!important;
//		margin-right: auto!important;
//	}
//	.um {
//		width: 100%;
//		text-align: left;
//		box-sizing: border-box;
//		color: #666;
//		font-size: 15px;
//		margin-bottom: 30px !important;
//		-webkit-font-smoothing: antialiased !important;
//		-moz-osx-font-smoothing: grayscale !important;
//		opacity: 0;
//	}
//
//  a {
//    color: color(link);
//    text-decoration: none;
//  }
//  a:hover {
//    text-decoration: underline;
//  }
//
//  .main-top {
//    margin-bottom: 20px;
//  }
//
//  .main-bottom {
//
//    .container-content {
//
//      .button{
//        border-radius: 11px;
//        color: black;
//        width:50%;
//        line-height: 45px;
//        font-weight: bold;
//        height:60px;
//        text-align: center;
//        margin: 0 auto;
//        align-content: center;
//        background-color: #ffffff;
//        border: 1px #8B8B8B solid;
//        -webkit-box-shadow: -1px 2px 5px 0px rgba(92,92,92,0.45), 1px 2px 5px 0px rgba(92,92,92,0.45) ;
//        -moz-box-shadow: -1px 2px 5px 0px rgba(92,92,92,0.45), 1px 2px 5px 0px rgba(92,92,92,0.45) ;
//        box-shadow: -1px 2px 5px 0px rgba(92,92,92,0.45), 1px 2px 5px 0px rgba(92,92,92,0.45) ;
//        margin-top: 1%;
//      }
//      .facebook{
//        color: white;
//        background-color: #4167b2;
//      }
//
//
//      #registration-form, #login-form {
//        .radio-customize input[type="radio"]{
//          opacity: 0;
//          width: 0;
//          text-align: center;
//        }
//        .radio-customize label {
//          background-color: #ffffff;
//          text-align: center;
//          padding: 15px 25px;
//          font-family: sans-serif, Arial;
//          font-size: 16px;
//          border: 2px solid #444;
//          border-radius: 4px;
//          color: black;
//          display: block;
//          height:55px;
//          -webkit-box-shadow: -3px 3px 5px 0px rgba(0,0,0,0.60);
//          -moz-box-shadow: -3px 3px 5px 0px rgba(0,0,0,0.60);
//          box-shadow: -3px 3px 5px 0px rgba(0,0,0,0.60);
//        }
//        .radio-customize input[type="radio"]:checked + label {
//          background-color:#121234;
//          border-color: #121234;
//          color: white;
//        }
//
//        .radio-customize input[type="radio"]:focus + label {
//          border: 2px solid #121234;
//        }
//
//        .separator {
//          display: flex;
//          align-items: center;
//          text-align: center;
//          font-size: 18px;
//          color: black;
//          font-weight: bold;
//        }
//        .separator::before, .separator::after {
//          content: '';
//          flex: 1;
//          border-bottom: 1px solid #000;
//        }
//        .separator::before {
//          margin-right: .25em;
//        }
//        .separator::after {
//          margin-left: .25em;
//        }
//
//        .title{
//          color: black;
//          font-weight: bolder;
//        }
//        input{
//          background-color: rgb(251,251,251);
//          //border: 1px solid #121234;
//        }
//        label{
//          font-weight: 600;
//          color: black;
//        }
//        .form-check {
//          padding: 8px 0;
//
//
//          .form-check-label {
//            line-height: 1.1;
//
//            small {
//              color: color(inactive);
//            }
//          }
//        }
//
//        #phraseName {
//          padding: 20px 0;
//        }
//
//        .clear-fields {
//          background-color: tint(cancel,50);
//
//          &:hover {
//            background-color: tint(cancel,40);
//          }
//        }
//
//        .btn-search {
//          cursor: pointer;
//          color: color(blue);
//          background-color: tint(cancel,50);
//
//          &:hover{
//            color: shade(blue,20);
//            background-color: tint(cancel,40);
//          }
//        }
//      }
//    }
//  }
//}
//
//#newNameLabel {
//	margin-top: 15px;
//}
//
//#modalAdd {
//
//  .modal-dialog {
//
//    @include respond-to(small) {
//      max-width: 100%;
//    }
//
//    @include respond-to(medium) {
//      max-width: 90%;
//    }
//
//    @include respond-to(large) {
//      max-width: 80%;
//    }
//
//    @include respond-to(big) {
//      max-width: 70%;
//    }
//  }
//}
//
//.radio-row {
//  padding-top: 10px;
//  padding-bottom: 10px;
//  font-size: 0.85em;
//
//  .radio:not(old) {
//    width: 30px;
//    margin: -30px -30px -30px 0;
//    padding: 0;
//    opacity: 0;
//  }
//
//  .radio:not(old) + .radio-inline{
//    display: inline-block;
//    cursor: pointer;
//    margin-left: 0;
//    padding-left: 30px;
//    margin-right: 5px;
//    padding-right: 5px;
//    line-height: 1em;
//    z-index: 5;
//    font-size: 1.2em;
//
//    span {
//      display: block;
//      float: left;
//      border: 1px solid color(fluid-silver);
//      border-radius: 50%;
//      background-color: white;
//      padding: 0;
//      margin: -3px 0 3px -30px;
//      width: 1.2em;
//      height: 1.2em;
//      font-size: 1.2em;
//      line-height: 1.2em;
//    }
//  }
//
//  .radio:not(old):checked + .radio-inline {
//    color: color(dark);
//
//    span {
//      border-color: color(blue);
//      background-color: color(blue);
//
//      &:before {
//        content: '✓';
//        display: block;
//        color: white;
//        font-size: 1.25em;
//        font-weight: bold;
//        margin: -0.06em -0.06em 0.06em 0.06em;
//        text-shadow: 1px 1px 1px shade(blue,40);
//      }
//    }
//  }
//}


main {

  /**
    Nowe style rejestracji
   */
  .registration {

    .registration-block {
      overflow: hidden;
      border-radius: 12px;

      //.registration-background img {
      //    background-size: cover;
      //    height: 100%;
      //    display: block;
      //
      //    -webkit-filter: blur(5px);
      //    -moz-filter: blur(5px);
      //    -o-filter: blur(5px);
      //    -ms-filter: blur(5px);
      //    filter: blur(5px);
      //    transform: scale(1.1);
      //    position: absolute;
      //    width: 100%;
      //    background-repeat: no-repeat;
      //}

      &.academy {
        &::before {
          background-image: url('/uploads/files/content/academy/security_bg.png');
        }
      }

      &.employers {
        &::before {
          background-image: url('/uploads/files/content/employers/security_bg.png');
        }
      }

      &.foodtrade {
        &::before {
          background-image: url('/uploads/files/content/foodtrade/security_bg.png');
        }
      }

      &::before {
        content: '';
        background-size: cover;
        height: 100%;
        display: block;
        -webkit-filter: blur(4px);
        -moz-filter: blur(4px);
        -o-filter: blur(4px);
        -ms-filter: blur(4px);
        filter: blur(4px);
        transform: scale(1.1);
        position: absolute;
        width: 100%;
        background-repeat: no-repeat;
      }

    }
  }
}